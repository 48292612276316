import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GreenButton } from "../components/button-green"

const NotFoundPage = (props) => {
  return (
    <Layout>
      <Seo
        title={"404: Page Not Found - 1st Call Junk Haul Sac"}
        location={props.location.pathname}
      />
      <div className="text-black pt-72 text-center pb-96 px-10">
        <h1 className="text-3xl font-bold pb-5">404: Page Not Found</h1>
        <p className="text-3xl max-w-5xl mx-auto font-medium leading-relaxed">
          Check the web address and try again or return to the 1st Call Junk Haul Sacramento Home Page
        </p>
        <div className="pt-5">
          <GreenButton buttonText="Home Page" buttonPage="/" />
        </div>
      </div>
    </Layout>
  )
}
export default NotFoundPage
